import React, { useEffect, useState } from 'react';
import SiteLayout from '../components/SiteLayout';
import { Location, navigate } from '@reach/router';
// import { Icon, Button, Form, Input } from 'antd';

// @ts-ignore
import AktiverHAN from '../../static/aktiverhan.inline.svg';

// @ts-ignore
import { image, button, input } from './index.module.scss';
import { Button, Form, Input } from "antd";
import { useTrackedSessionStoreStore } from "../state/sessionStore";

interface ILookupFormProps {
    isEmbeded: boolean;
}

const LookupForm = (props: ILookupFormProps) => {


    const [activateLoading, setActivateLoading] = useState<boolean>(false);
    const [deactivateLoading, setDeactivateLoading] = useState<boolean>(false);

    const [form] = Form.useForm();

    const sessionStore = useTrackedSessionStoreStore();

    const handleSubmit = async (_type) => {
        try {
            const values = await form.validateFields();

            if (_type === 0) setActivateLoading(true);
            if (_type === 1) setDeactivateLoading(true);

            let embeded = "";
            props.isEmbeded ? embeded = "/embeded/" : embeded = "/";
            const request = await sessionStore.lookup({ lookupRequest: { meterId: values.idNumber, postNumber: values.postNumber, type: _type } });

            if (!request.canActivate) {
                navigate(embeded + 'oida');
            } else {
                navigate(embeded + 'velg');
            }
        } catch (err) {
            setActivateLoading(false);
            setDeactivateLoading(false);
        }
    };

    return (
        <Form layout="vertical" form={form}>
            <Form.Item
                rules={[{ required: true, message: 'Tast inn postnummer' }]}
                name="postNumber"
                label=""
            >

                <Input size="large" className={input}
                    type="number"
                    placeholder="Postnummer" disabled={activateLoading || deactivateLoading}
                />
            </Form.Item>
            <Form.Item

                rules={[{ required: true, message: 'Tast inn målernummer' }]}
                name="idNumber"
                label=""
            >
                <Input size="large" className={input}
                    type="number"
                    placeholder="Målernummer" disabled={activateLoading || deactivateLoading}
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" onClick={() => handleSubmit(0)} size="large" loading={activateLoading} disabled={activateLoading || deactivateLoading} className={button}>
                    AKTIVER HAN PORTEN
                </Button>
                <br />
                <Button type="link" onClick={() => handleSubmit(1)} size="large" style={{ fontSize: 12 }} loading={deactivateLoading} disabled={deactivateLoading || activateLoading} className={button}>
                    Deaktiver HAN porten
                </Button>
            </Form.Item>
        </Form>
    );
};

export function IndexComponent({ isEmbeded }) {
    return (<LookupForm isEmbeded={isEmbeded} />);
}

function Index({ props }) {

    return (<Location>
        {({ location, navigate }) => (
            <SiteLayout location={location}>
                <AktiverHAN className={image} />
                <br />
                <h1>Aktiver HAN</h1>
                <p style={{ fontWeight: 400 }}>Aktiver HAN porten din ved bruk av BankID</p>
                <br />
                <IndexComponent isEmbeded={false} />
            </SiteLayout>
        )}
    </Location>);
}

export default Index;
